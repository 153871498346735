<!-- 支付成功页面 -->
<template>
  <div v-if="showPage" class="container">
    <div class="page_top">
      <div class="pay_icon">
        <img src="@/assets/image/paysuccess.png" alt />
      </div>
      <div class="pay_money">
        <span>￥</span>
        <span class="yuan">{{ $price }}</span>
      </div>
      <div class="pay_free_time">支付完成</div>
    </div>
    <van-button
      class="complete"
      @click="complete"
      type="primary"
      color="#16B19A"
      >完成</van-button
    >
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { Notify } from "vant";
import { closeWebviewEvent } from "@/utils/common";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      showPage: true,
      $price: 0
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    complete() {
      closeWebviewEvent();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    window.addEventListener("popstate", closeWebviewEvent, false);
    const obj = this.$route.query;
    this.$price = obj.p;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  destroyed() {
    window.removeEventListener("popstate", closeWebviewEvent, false);
    Notify.clear();
  }
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.container {
  position: relative;
  height: 100vh;
  text-align: center;
  background: #f6f6f6;
  font-size: 28px;
  .page_top {
    padding: 64px 0;
    background: #fff;
    .pay_icon {
      img {
        width: 92px;
        height: 92px;
      }
    }
    .pay_money {
      font-family: Roboto-Regular, Roboto, sans-serif;
      font-weight: 400;
      color: #000000;
      font-size: 40px;
      height: 140px;
      line-height: 140px;
      .yuan {
        font-size: 64px;
      }
      .point {
        font-size: 56px;
      }
    }
    .pay_free_time {
      color: #444444;
    }
    .invoice {
      text-align: center;
      height: 40px;
      margin-top: 60px;
      img {
        width: 32px;
        height: 32px;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        margin-left: 8px;
        font-size: 28px;
        height: 32px;
        line-height: 32px;
        color: #24b6a1;
        vertical-align: middle;
      }
    }
  }

  .complete {
    position: absolute;
    bottom: 68px;
    left: 32px;
    width: 686px;
    border-radius: 8px;
  }
  .completes {
    margin-top: 40px;
    width: 686px;
  }
}
</style>